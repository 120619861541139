import React, { useState, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../global.css';

const Slider = ({ features, fontColor = "#ffe6c8", heading }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(4);
  const [displayFeatures, setDisplayFeatures] = useState([]);
  const [isHoveredPrev, setIsHoveredPrev] = useState(false);
  const [isHoveredNext, setIsHoveredNext] = useState(false);

  // Retrieve colors from localStorage once
  const herobgColorStored = localStorage.getItem('herobgColor');
  const heroFontColorStored = localStorage.getItem('heroFontColor');

  // Validate color values
  const herobgColor = herobgColorStored && herobgColorStored !== 'undefined' ? herobgColorStored : "#1c2933";
  const heroFontColor = heroFontColorStored && heroFontColorStored !== 'undefined' ? heroFontColorStored : '#ffe6c8';

  useEffect(() => {
    const handleResize = () => {
      setItemsToShow(window.innerWidth <= 576 ? 1 : 4);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initialize based on current window width

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const adjustedFeatures = [...features];
    if (features.length % itemsToShow !== 0) {
      const dummyCount = itemsToShow - (features.length % itemsToShow);
      for (let i = 0; i < dummyCount; i++) {
        adjustedFeatures.push({
          link: '#',
          imageSrc: '/dummy.png',
          title: '',
          description: '',
          isDummy: true
        });
      }
    }
    setDisplayFeatures(adjustedFeatures);
  }, [features, itemsToShow]);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, displayFeatures.length - itemsToShow));
  };

  const calculateTransform = () => {
    return `translateX(calc(-${(currentIndex * 100) / itemsToShow}%))`;
  };

  if (displayFeatures.length === 0) {
    return <div>No features available.</div>;
  }

  return (
    <div className="container-fluid p-0 pt-5">
      <div className="scroll-items">
        <div className="d-flex justify-content-between align-items-center pr-1 vp-container">
          <h2
            className="custom-large-heading text-start pt-3 mb-0 common-textshadow"
            style={{ color: fontColor }}
          >
            {heading}
          </h2>
          <div>
            {/* Previous Button */}
            <button
              className="btn btn-prev me-2 common-boxshadow"
              onClick={prevSlide}
              aria-label="Previous Slide"
              onMouseEnter={() => setIsHoveredPrev(true)}
              onMouseLeave={() => setIsHoveredPrev(false)}
              style={{
                color: isHoveredPrev ? herobgColor : heroFontColor,
                background: isHoveredPrev ? heroFontColor : herobgColor,
                borderColor: isHoveredPrev ? herobgColor : heroFontColor,
                transition: 'all 0.3s ease', // Smooth transition
              }}
            >
              <i className="fas fa-arrow-left"></i>
            </button>

            {/* Next Button */}
            {currentIndex < displayFeatures.length - itemsToShow && (
              <button
                className="btn btn-next common-boxshadow"
                onClick={nextSlide}
                aria-label="Next Slide"
                onMouseEnter={() => setIsHoveredNext(true)}
                onMouseLeave={() => setIsHoveredNext(false)}
                style={{
                  color: isHoveredNext ? herobgColor : heroFontColor,
                  background: isHoveredNext ? heroFontColor : herobgColor,
                  borderColor: isHoveredNext ? herobgColor : heroFontColor,
                  transition: 'all 0.3s ease', // Smooth transition
                }}
              >
                <i className="fas fa-arrow-right"></i>
              </button>
            )}
          </div>
        </div>

        <div className="slider-content d-flex" style={{ transform: calculateTransform() }}>
          {displayFeatures.map((feature, index) => {
            const hrefPrefix = heading === "Features" ? "feature" : "educator";
            return (
              <div
                key={index}
                className={`slider-item col-12 col-md-6 col-lg-3 my-4 ${feature.isDummy ? 'd-none' : ''}`}
              >
                <a
                  href={heading === "Research" ? `${feature.href}` : `/${hrefPrefix}=${feature.title}`}
                  className="text-decoration-none"
                  target="_blank"
                  rel="noopener noreferrer" // Added for security
                >
                  <img
                    src={feature.imageSrc}
                    alt={feature.title || "Image not found"}
                    className="img-fluid rounded-top rounded-0 common-boxshadow"
                  />
                  <div className='text-center position-absolute w-100' style={{ top: '53%', zIndex: '9' }}>
                    <span
                      className="scroll-item mb-1 scroll-text montser-mdm-1rem common-textshadow"
                      style={{ color: fontColor }}
                    >
                      {feature.title}
                    </span>
                    <br />
                    <span
                      className="scroll-item mb-1 montser-sml-LightItalic-1rem"
                      style={{ color: fontColor }}
                    >
                      {feature.description}
                    </span>
                  </div>
                  <div
                    className="slider-overlay card-overlay"
                    style={{
                      backgroundColor: herobgColor,
                      opacity: '80%',
                      transition: 'background-color 0.3s ease', // Smooth transition
                    }}
                  ></div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Slider;