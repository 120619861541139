import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from './Slider';
import Blogs from './Blogs';
import Users from './Users';
import Research from '../research/research';

const ScrollItems = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const subDomain = localStorage.getItem('subDomain');
  let schoolName = subDomain;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://membership.virtupreplabs.com/school/v1/getScrollitemsDataForUsers', {
          params: { school: schoolName },
        });
        if (response.data.success === false) {
          setError(response.data.message);
        } else {
          setData(response.data);
        }
      } catch (err) {
        setError("Error loading scroll items data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [schoolName]);
  
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!data || data === " ") return <div>...Loading</div>;

  return (
    <div className="container-fluid pb-5">
     {subDomain === "virtupreplabs" && (
        <div className="p-5">
          <p className="custom-large-heading">
            <span className="d-block p-4">School Choice is here</span>
            <span className="d-block p-4">Does your school stand out?</span>
            <span className="d-block p-4">We build the tools that elevate your school</span>
            <span className="d-block p-4">Show what makes your school special with data</span>
          </p>
        </div>
      )}
      {data && data.features && <Slider features={data.features} fontColor={localStorage.getItem("heroFontColor")} heading="Features" />}
      {subDomain === "virtupreplabs" && <Blogs />}
      <Research />
      {data && data.users && <Users users={data.users}/>}
    </div>
  );
};

export default ScrollItems;
