import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import "@fontsource/montserrat";
import axios from 'axios';
import AppNavigations from './navigations/AppNavigations';

const App = ({ hostname }) => {
  const [favicon, setFavicon] = useState('');

  useEffect(() => {
    document.title = hostname;
  }, [hostname]);

  useEffect(() => {
    const fetchData = async () => {
      const schoolName = localStorage.getItem('subDomain');

      try {
        const response = await axios.get('https://membership.virtupreplabs.com/school/v1/getNavbarDetails', {
          params: { school: schoolName },
        });

        if (response.data.success === false) {
          console.error(response.data.message);
        } else {
          const logoUrl = response.data.logo.logo;
          console.log('Fetched logo URL:', logoUrl);
          setFavicon(logoUrl); // Set the fetched favicon URL
        }
      } catch (err) {
        console.error("Error loading data:", err);
      }
    };

    fetchData();
  }, []);

  // Update favicon whenever it changes
  useEffect(() => {
    if (favicon) {
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/png';
      link.rel = 'icon';
      link.href = favicon;
      document.getElementsByTagName('head')[0].appendChild(link);
    }
  }, [favicon]);

  useEffect(() => {
    window.addEventListener('resize', adjustHeightWidth);
    adjustHeightWidth(); 
    return () => {
      window.removeEventListener('resize', adjustHeightWidth);
    };
  }, []);

  const adjustHeightWidth = () => {
    const viewportHeight = window.innerHeight; 
    const viewportWidth = window.innerWidth; 

    const setElementSize = (elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.height = `${viewportHeight}px`;
        element.style.width = `${viewportWidth}px`;
      }
    };
  
    setElementSize("mainPage");
  };

  return (
    <BrowserRouter>
      <AppNavigations />
    </BrowserRouter>
  );
};

export default App;
