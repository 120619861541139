import React, { useRef, useState } from 'react';
import './videobackground.css';

const EvidenceModal = ({ isOpen, onClose }) => {
    const modalRef = useRef(null);
    const videoRef = useRef(null);
    const [isMuted, setIsMuted] = useState(true);  // State to manage mute/unmute

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();  // Close the modal when clicking outside the modal content
        }
    };

    const toggleMute = () => {
        setIsMuted(!isMuted);  // Toggle the mute state
        if (videoRef.current) {
            videoRef.current.muted = !videoRef.current.muted;  // Set video muted property
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={handleClickOutside}>
            <div className="modal-content common-boxshadow" ref={modalRef}>
                <div className="modal-body">
                    <div className="evidence-content d-flex flex-column">
                        <div className="container-e m-container-e">
                            <div className="d-flex mt-2 pr-4 gap-2">
                                <div className="img-l">
                                    <img 
                                        src="logos/Danielle Leek.jpeg" 
                                        alt="Profile" 
                                        className="img-fluid rounded-circle"
                                    />
                                </div>
                                <div className="d-flex flex-column text-start montser-mdm text-div">
                                        <span className="small lh-3 font-italic fw-bolder">Danielle Leek,</span>
                                        <span className="small lh-3 font-italic">Ph.D, MBA Lecturer, John's Hopkins</span>
                                        <span className="small lh-3 font-italic">Instructional Faculty, College Unbound</span>
                                </div>
                                <div className="d-flex flex-column justify-content-center text-left text-container">
                                    <span className="montser-mdm">
                                        "I think that the VirtuPrep platform shows how the insightful application of learning science can blend with an engaging approach to student motivation in order to really advance the way we think about education. I'm confident students who use the system will be energized to learn!"
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="container-e d-flex flex-wrap justify-content-around pt-5">
                            <div className="graph-svg align-items-center mb-3">
                                <img 
                                    src="https://virtuprep-gallary.nyc3.digitaloceanspaces.com/Virtu Prep Data.svg" 
                                    alt="Graph" 
                                    className="img-fluid"
                                />
                            </div>
                            <div className="graph-svg align-items-center text-left flex-column mb-3">
                                <span className="montser-mdm text-left fw-bolder lh-3 " style={{fontSize:'0.7rem'}}>
                                    Charles Youn </span><br></br> 
                                <span className="font-weight-normal montser-mdm lh-3 "style={{fontSize:'0.7rem'}}>Managing Director at Teach For America</span>
                               
                                <div className="video-container" style={{ position: 'relative' }}>
                                    <video 
                                        autoPlay 
                                        loop  
                                        playsInline
                                        muted={isMuted}  // Set initial mute state
                                        ref={videoRef}
                                        className="w-100" 
                                        style={{ maxHeight: "80%" }}
                                    >
                                        <source src="videos/Charles Youn_Testimonial_2.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    <button 
                                        className="mute-toggle" 
                                        onClick={toggleMute} 
                                        style={{
                                            position: 'absolute',
                                            top: '10px',
                                            right: '10px',
                                            background: 'rgba(0, 0, 0, 0.5)',
                                            border: 'none',
                                            color: '#fff',
                                            padding: '5px',
                                            borderRadius: '50%'
                                        }}
                                    >
                                        {isMuted ? '🔇' : '🔊'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EvidenceModal;
